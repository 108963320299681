<div
  class="card lesson-form mb-4 mb-md-5"
  *ngIf="lessonDetails && (isComplete || isRunning || shouldBeStarted)"
>
  <div class="card-header">
    <span>{{
      "COL.TEACHER.LESSON_DETAILS.COMMITED_PROGRESS.TITLE" | translate
    }}</span>
  </div>
  <div class="card-body">
    <form #f="ngForm" (ngSubmit)="f.valid && commitLesson()">
      <div class="row">
        <div class="col-12 col-md-6 col-xl-4 mb-4">
          <div class="form-floating">
            <input
              class="form-control input-arrows-none"
              type="number"
              name="comStart"
              id="comStart"
              placeholder="comStart"
              [(ngModel)]="lessonDetails.progressCommited.start"
              #comStart="ngModel"
              [ngClass]="{'is-invalid': f.submitted && comStart.invalid, 'pe-none': !canUpdateProgress }"
              required
            />
            <label class="required">{{
              "COL.TEACHER.LESSON_DETAILS.COMMITED_PROGRESS.START_PAGE"
                | translate
            }}</label>
            <div
              *ngIf="f.submitted && comStart.invalid"
              class="invalid-feedback"
            >
              <div *ngIf="comStart.errors?.['required']">
                {{ "FORM_ERROR.FIELD_REQUIRED" | translate }}
              </div>
              <div *ngIf="comStart.errors?.['pattern']">
                {{ "FORM_ERROR.ONLY_NUMBERS" | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-xl-4 mb-4">
          <div class="form-floating">
            <input
              class="form-control input-arrows-none"
              type="number"
              id="comNwp"
              name="comNwp"
              placeholder="comNwp"
              #comNwp="ngModel"
              [(ngModel)]="lessonDetails.progressCommited.nwp"
              [ngClass]="{ 'pe-none': !canUpdateProgress }"
              [ngClass]="{ 'is-invalid': f.submitted && comNwp.invalid }"
              required
            />
            <label for="comNwp" class="required">{{
              "COL.TEACHER.LESSON_DETAILS.COMMITED_PROGRESS.NEW_WORK_PAGE"
                | translate
            }}</label>
            <div *ngIf="f.submitted && comNwp.invalid" class="invalid-feedback">
              <div *ngIf="comNwp.errors?.['required']">
                {{ "FORM_ERROR.FIELD_REQUIRED" | translate }}
              </div>
              <div *ngIf="comNwp.errors?.['pattern']">
                {{ "FORM_ERROR.ONLY_NUMBERS" | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-xl-4 mb-4">
          <div class="form-floating">
            <input
              class="form-control input-arrows-none"
              type="number"
              id="comReading"
              name="comReading"
              placeholder="comReading"
              #comReading="ngModel"
              [(ngModel)]="lessonDetails.progressCommited.reading"
              [ngClass]="{ 'pe-none': !canUpdateProgress }"
              [ngClass]="{ 'is-invalid': f.submitted && comReading.invalid }"
            />
            <label for="comReading">{{
              "COL.TEACHER.LESSON_DETAILS.COMMITED_PROGRESS.READING" | translate
            }}</label>
            <div
              *ngIf="f.submitted && comReading.invalid"
              class="invalid-feedback"
            >
              <div *ngIf="comReading.errors?.['required']">
                {{ "FORM_ERROR.FIELD_REQUIRED" | translate }}
              </div>
              <div *ngIf="comReading.errors?.['pattern']">
                {{ "FORM_ERROR.ONLY_NUMBERS" | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-xl-4 mb-4">
          <div class="form-floating">
            <input
              class="form-control"
              type="text"
              id="comHw"
              name="comHw"
              placeholder="comHw"
              [(ngModel)]="lessonDetails.progressCommited.hw"
              #comHw="ngModel"
              [ngClass]="{ 'pe-none': !canUpdateProgress }"
              [ngClass]="{ 'is-invalid': f.submitted && comHw.invalid }"
              required
            />
            <label for="comHw" class="required">{{
              "COL.TEACHER.LESSON_DETAILS.COMMITED_PROGRESS.LAST_HEADWORD"
                | translate
            }}</label>
            <div *ngIf="f.submitted && comHw.invalid" class="invalid-feedback">
              <div *ngIf="comHw.errors?.['required']">
                {{ "FORM_ERROR.FIELD_REQUIRED" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="row">
          <div class="col-12 col-md-6 mb-4">
            <div class="form-floating">
              <textarea
                id="comment"
                name="comment"
                placeholder="comment"
                class="form-control textarea-height"
                [(ngModel)]="lessonDetails.message.lessonComment"
                [ngClass]="{ 'pe-none': !canUpdateProgress }"
                #comment="ngModel"
              ></textarea>
              <label for="comment">{{
                "COL.TEACHER.LESSON_DETAILS.COMMITED_PROGRESS.LESSON_COMMENT"
                  | translate
              }}</label>
            </div>
          </div>
          <div class="col-12 col-md-6 mb-4">
            <div class="form-floating">
              <textarea
                class="form-control textarea-height"
                name="studentMessage"
                id="studentMessage"
                placeholder="studentMessage"
                [(ngModel)]="lessonDetails.studentMessage"
                [ngClass]="{ 'pe-none': !canUpdateProgress }"
                #studentMessage="ngModel"
              ></textarea>
              <label for="studentMessage">{{
                "COL.TEACHER.LESSON_DETAILS.COMMITED_PROGRESS.MSG_FOR_STUDENT"
                  | translate
              }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 col-xl-4 mb-4">
          <div class="form-floating">
            <select
              class="form-select"
              id="lessonType"
              name="lessonType"
              [(ngModel)]="newLessonType"
              [ngClass]="{
              'pe-none': !canUpdateProgress,
              'select-not-empty' : newLessonType,
              'is-invalid': f.submitted && lessonType.invalid
              }"
              required
              #lessonType="ngModel"
            >
              <option value="Standard">
                {{ "COL.LESSON_TYPE.STANDARD" | translate }}
              </option>
              <option value="Revision">
                {{ "COL.LESSON_TYPE.REVISION" | translate }}
              </option>
              <option value="QuickStageRevision">
                {{ "COL.LESSON_TYPE.QUICK_STAGE_REVISION" | translate }}
              </option>
              <option value="Introduction">
                {{ "COL.LESSON_TYPE.INTRODUCTION" | translate }}
              </option>
              <option value="Exam">
                {{ "COL.LESSON_TYPE.EXAM" | translate }}
              </option>
              <option value="Prova">
                {{ "COL.LESSON_TYPE.PROVA" | translate }}
              </option>
            </select>
            <label for="lessonType" class="required">{{
              "COL.TEACHER.LESSON_DETAILS.COMMITED_PROGRESS.CURRENT_LESSON_TYPE"
                | translate
            }}</label>
            <div
              *ngIf="f.submitted && lessonType.invalid"
              class="invalid-feedback"
            >
              <div *ngIf="lessonType.errors['required']">
                {{ "FORM_ERROR.FIELD_REQUIRED" | translate }}
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="studentContext" class="col-12 col-md-6 col-xl-4 mb-4">
          <div class="form-floating">
            <select
              class="form-select"
              id="comingLessonType"
              name="comingLessonType"
              [(ngModel)]="studentContext.nextLessonType"
              [ngClass]="{
              'pe-none': !canUpdateProgress,
              'select-not-empty' : studentContext.nextLessonType,
              'is-invalid': f.submitted && comingLessonType.invalid
              }"
              required
              #comingLessonType="ngModel"
            >
            <option value="Standard">
              {{ "COL.LESSON_TYPE.STANDARD" | translate }}
            </option>
            <option value="Revision">
              {{ "COL.LESSON_TYPE.REVISION" | translate }}
            </option>
            <option value="QuickStageRevision">
              {{ "COL.LESSON_TYPE.QUICK_STAGE_REVISION" | translate }}
            </option>
            <option value="Introduction">
              {{ "COL.LESSON_TYPE.INTRODUCTION" | translate }}
            </option>
            <option value="Exam">
              {{ "COL.LESSON_TYPE.EXAM" | translate }}
            </option>
            <option value="Prova">
              {{ "COL.LESSON_TYPE.PROVA" | translate }}
            </option>
            </select>
            <label for="comingLessonType" class="required">{{
              "COL.TEACHER.LESSON_DETAILS.COMMITED_PROGRESS.COMING_LESSON_TYPE"
                | translate
            }}</label>
            <div
              *ngIf="f.submitted && comingLessonType.invalid"
              class="invalid-feedback"
            >
              <div *ngIf="comingLessonType.errors['required']">
                {{ "FORM_ERROR.FIELD_REQUIRED" | translate }}
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="studentContext" class="col-12 col-md-6 col-xl-4 mb-4">
          <div class="form-floating">
            <select
              class="form-select"
              id="studentsStage"
              name="studentsStage"
              [(ngModel)]="studentContext.currentCourse"
              [ngClass]="{
              'pe-none': !canUpdateProgress,
              'select-not-empty' : studentContext.currentCourse,
              'is-invalid': f.submitted && studentsStage.invalid
              }"
              required
              #studentsStage="ngModel"
            >
              <option *ngFor="let course of productCourses" [ngValue]="course">
                {{ course.name }}
              </option>
            </select>
            <label for="studentsStage" class="required">{{
              "COL.TEACHER.LESSON_DETAILS.COMMITED_PROGRESS.STUDENT_STAGE"
                | translate
            }}</label>
            <div
              *ngIf="f.submitted && studentsStage.invalid"
              class="invalid-feedback"
            >
              <div *ngIf="studentsStage.errors['required']">
                {{ "FORM_ERROR.FIELD_REQUIRED" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="
          lessonDetails && lessonDetails.flags && lessonDetails.flags.length > 0
        "
      >
        <ul>
          <li *ngFor="let flag of lessonDetails.flags">
            {{ getFlagDescription(flag.flagType) }}
            <span *ngIf="flag.description"
              ><br />
              {{
                "COL.TEACHER.LESSON_DETAILS.COMMITED_PROGRESS.FLAG_DESCRIPTION"
                  | translate
              }}
              {{ flag.description }}
            </span>
          </li>
        </ul>
      </div>
      <div class="row" *ngIf="canUpdateProgress">
        <div class="col-12 text-md-end">
          <button
            *ngIf="!isComplete"
            type="submit"
            class="btn btn-primary rounded-pill me-2 mb-2"
          >
            {{ "BUTTON.SAVE_PROGRESS_FINISH_LESSON" | translate }}
          </button>
          <button
            *ngIf="isComplete && f.touched"
            class="btn btn-primary rounded-pill me-2 mb-2"
            type="submit"
          >
            {{ "BUTTON.UPDATE_PROGRESS" | translate }}
          </button>
          <button
            [attr.disabled]="isComplete ? null : ''"
            type="button"
            class="btn btn-secondary rounded-pill me-2 mb-2"
            (click)="showFlagsModal()"
          >
            {{ "BUTTON.ATTACH_FLAG" | translate }}
          </button>
          <button
            type="button"
            class="btn btn-danger rounded-pill me-2 mb-2"
            *ngIf="cancelable"
            (click)="showCancelLessonModal()"
          >
            {{ "BUTTON.CANCEL" | translate }}
          </button>
          <button
            type="button"
            class="btn btn-danger rounded-pill me-2 mb-2"
            *ngIf="isComing && !cancelable"
            (click)="showCancelLessonModal()"
          >
            {{ "BUTTON.DISMISS" | translate }}
          </button>
          <button
            type="button"
            class="btn btn-danger rounded-pill me-2 mb-2"
            *ngIf="haveGift()"
            (click)="giveGift()"
          >
            {{ "BUTTON.GIVE_EBOOK" | translate : getGiftEbookName() }}
          </button>
          <span *ngIf="!hasStudentNoShowFlag()" [attr.data-bs-toggle]="{ 'tooltip' : !isComplete }"
               title="Finish the lesson to report student no-show">
            <button
              type="button"
              class="btn btn-danger rounded-pill me-2 mb-2"
              (click)="studentNoShowModal.show()"
              [disabled]="!isComplete"
            >
              {{ "BUTTON.STUDENT_NO_SHOW" | translate }}
            </button>
          </span>
          <button
            *ngIf="isOldEnglishVersion()"
            type="button"
            class="btn btn-success rounded-pill mb-2"
            (click)="migrateToNewEnglishConfirmationModal.show()">
            Switch edition
          </button>

        </div>
      </div>
    </form>
  </div>
</div>
<app-modal #migrateToNewEnglishConfirmationModal>
  <div class="modal-content" *ngIf="lessonDetails">
    <div class="modal-header border-0">
      <h5 class="modal-title">Confirm switch</h5>
      <button
        type="button"
        class="btn-close"
        (click)="migrateToNewEnglishConfirmationModal.hide()"
        aria-label="Close"
      ></button>
    </div>
    <div class="modal-body">
      Are you sure you want to switch this Student to 3rd Edition?
    </div>
    <div class="modal-footer border-0">
      <button type="button" class="btn btn-secondary rounded-pill" (click)="migrateToNewEnglishConfirmationModal.hide()">Cancel</button>
      <button type="submit" class="btn btn-primary rounded-pill" [disabled]="isMigratingInProgress" (click)="migrateToNewEnglish()">
        Confirm
        <div *ngIf="isMigratingInProgress" class="spinner-border spinner-border-sm text-light" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </button>
    </div>
  </div>
</app-modal>
<app-modal #studentNoShowModal>
  <div class="modal-content">
    <div class="modal-header border-0">
      <h5 class="modal-title">Student no-show</h5>
      <button
        type="button"
        class="btn-close"
        (click)="studentNoShowModal.hide()"
        aria-label="Close"
      ></button>
    </div>
    <div class="modal-body">
      Are you sure?
    </div>
    <div class="modal-footer border-0">
      <button type="button" class="btn btn-secondary rounded-pill" (click)="studentNoShowModal.hide()">Cancel</button>
      <button type="submit" class="btn btn-primary rounded-pill" (click)="studentNoShow(); studentNoShowModal.hide()">
        Yes, the student was absent
      </button>
    </div>
  </div>
</app-modal>

